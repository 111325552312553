@media screen and (max-height: 760px) {
  .wheel {
    &__spin {
      padding: 12px 16px;
      border-radius: 45px;

      span {
        font-size: 19px;
        line-height: 23px;
      }
    }
  }

  html[theme="lightPurple"] {
    .wheel {
      &__spin {
        margin-top: 20px;
        padding: 16px 22px;

        span {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }

  html[theme="pink"] {
    .wheel-wrap {
      margin-bottom: 100px;
    }

    .wheel {
      margin-left: 150px;

      &__spin {
        margin-top: 20px;

        span {
          font-size: 15px;
          line-height: 19px;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wheel {
    &__spin {
      padding: 12px 16px;
      border-radius: 45px;

      span {
        font-size: 19px;
        line-height: 23px;
      }
    }
  }

  html[theme="purple"] {
    .wheel {
      &__chip {
        bottom: -7%;
        left: 6%;
        width: 125px;
      }

      &__crown {
        width: 90px;
      }
    }
  }

  html[theme="pink"] {
    .wheel-wrap {
      margin-bottom: 140px;
    }

    .wheel {
      margin-left: 200px;
    }
  }

  html[theme="orange"] {
    .wheel {
      &__spin {
        span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  html[theme="lightPurple"] {
    .wheel {
      &__spin {
        margin-top: 20px;
        padding: 16px 22px;

        span {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) and (max-height: 760px) {
  html[theme="pink"] {
    .wheel-wrap {
      margin-bottom: 100px;
    }

    .wheel {
      margin-left: 160px;

      &__spin {
        margin-top: 20px;

        span {
          font-size: 15px;
          line-height: 19px;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .wheel-wrap {
    flex-direction: column;
  }

  html[theme="purple"] {
    .wheel {
      bottom: 13%;
    }
  }

  html[theme="pink"] {
    .wheel-wrap {
      align-items: flex-start;
      margin-left: 150px;
    }

    .wheel {
      order: 1;
      margin: 0;

      &__spin {
        margin-top: 20px;
      }
    }
  }

  html[theme="orange"] {
    .wheel {
      order: 1;
      margin: 0;
    }
  }

  html[theme="lightPurple"] {
    .wheel {
      margin: 0;
    }
  }
}

@media screen and (max-width: 990px) and (max-height: 760px) {
  html[theme="purple"] {
    .wheel {
      bottom: 15%;
    }
  }
}

@media screen and (max-height: 860px) {
  html[theme="purple"] {
    .wheel {
      &__chip {
        bottom: -5%;
        left: 8%;
        width: 140px;
      }
    }
  }
}

@media screen and (max-height: 720px) {
  html[theme="purple"] {
    .wheel {
      &__chip {
        bottom: -5%;
        left: 8%;
        width: 115px;
      }
    }
  }
}

@media screen and (max-height: 580px) {
  html[theme="purple"] {
    .wheel {
      &__chip {
        bottom: -5%;
        left: 8%;
        width: 85px;
      }

      &__crown {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  html[theme="pink"] {
    .wheel-wrap {
      align-items: center;
      margin: 0;
    }
  }
}

@media screen and (max-width: 520px) {
  .wheel-wrap {
    position: relative;
  }

  .wheel {
    &__spin {
      padding: 9px 16px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  html[theme="purple"] {
    .wheel {
      position: relative;
      bottom: auto;

      &__chip {
        display: none;
      }

      &__crown {
        display: none;
      }
    }
  }

  html[theme="pink"] {
    .wheel {
      &__spin {
        margin: 0;
        position: absolute;
        bottom: -90px;
        padding: 16px 40px;

        span {
          font-size: 24px;
        }
      }
    }
  }

  html[theme="orange"] {
    .wheel {
      &__spin {
        span {
          font-size: 9px;
          line-height: normal;
        }
      }
    }
  }

  html[theme="lightPurple"] {
    .wheel {
      &__spin {
        margin: 0;
        position: absolute;
        bottom: -110px;
        padding: 24px 40px;

        span {
          font-size: 32px;
          line-height: 36px;
        }
      }
    }
  }
}
