.won-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 440px;
  min-height: 480px;
  padding: 16px;
  background: var(--win-modal-background) no-repeat center / cover;

  .content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: white;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 32px;
      font-weight: 800;
      line-height: 39px;
    }

    > p {
      width: 300px;
      margin-top: 24px;
      color: white;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .amount-wrap {
    margin-top: 24px;

    h3 {
      color: white;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
    }

    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 3px;
      color: #E8C657;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 39px;
    }
  }

  button {
    padding: 18px 30px;
    margin-top: 32px;
    background: #FFD02F;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    color: #020015;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }
}

html[theme="pink"] {
  .won-content {
    .content-wrap {
      width: 380px;
      padding: 16px 0;
      background: #C599F4;
      border-radius: 16px;
    }

    .amount-wrap {
      h3 {
        display: none;
        color: white;
      }

      p {
        color: white;

        span {
          order: 1;
          margin-left: 10px;
        }
      }
    }

    button {
      width: 380px;
      background: #C599F4;
      color: white;
    }
  }
}

html[theme="orange"] {
  .won-content {
    .amount-wrap {
      padding: 16px;
      background: #F3853B;
      border-radius: 18px;

      h3 {
        display: none;
        color: white;
      }

      p {
        color: white;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}

html[theme="lightPurple"] {
  .won-content {
    .amount-wrap {
      h3 {
        display: none;
      }

      p {
        span {
          margin-right: 10px;
          font-size: 60px;
          line-height: 73px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .modal.win {
    width: 90%;
    max-width: 100%;
    scale: 1;
  }

  .won-content {
    width: 100%;
    min-height: 320px;
  }

  html[theme="pink"] {
    .won-content {
      .content-wrap {
        width: 100%;

        h1 {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        > p {
          width: auto;
          margin-top: 24px;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .amount-wrap {
        p {
          margin-top: 0;
          font-size: 24px;
          line-height: 29px;
        }
      }

      button {
        padding: 8px 16px;
        margin-top: 24px;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  html[theme="orange"] {
    .won-content {
      .content-wrap {
        width: 100%;

        h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
        }

        > p {
          width: auto;
          margin-top: 24px;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .amount-wrap {
        width: 100%;

        p {
          margin-top: 0;
          font-size: 24px;
          line-height: 29px;

          span {
            width: 100%;
            font-size: 40px;
            line-height: 48px;
          }
        }
      }

      button {
        padding: 8px 16px;
        margin-top: 24px;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  html[theme="lightPurple"] {
    .won-content {
      .content-wrap {
        width: 100%;

        h1 {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        > p {
          width: auto;
          margin-top: 24px;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .amount-wrap {
        width: 100%;

        p {
          margin-top: 0;
          font-size: 24px;
          line-height: 29px;

          span {
            width: 100%;
            font-size: 48px;
            line-height: 58px;
          }
        }
      }

      button {
        padding: 8px 16px;
        margin-top: 24px;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
