html[theme="green"] {
  --wheel-deg: 0;
  --wheel-radius: 0;
  --wheel-size: 667px;
  --wheel-body-size: 89%;
  --bonus-type-font-size: 30px;
  --bonus-amount-font-size: 38px;
  --reg-bonus-type-font-size: 13px;
  --reg-bonus-amount-font-size: 15px;
  --bonus-type-line-height: 47px;
  --bonus-type-top: 0px;
  --bonus-amount-bottom: 47%;
  --bonus-type-font-weight: 700;
  --bonus-amount-font-weight: 800;
  --wheel-type-text-font-family: "Bowlby One", sans-serif;
  --wheel-amount-text-font-family: "Montserrat", sans-serif;
}

html[theme="purple"] {
  --wheel-deg: 0;
  --wheel-radius: 0;
  --wheel-size: 600px;
  --wheel-body-size: 80%;
  --bonus-type-font-size: 28px;
  --bonus-amount-font-size: 28px;
  --reg-bonus-type-font-size: 11px;
  --reg-bonus-amount-font-size: 15px;
  --bonus-type-font-weight: 400;
  --bonus-type-line-height: normal;
  --bonus-type-top: 15px;
  --bonus-amount-bottom: 39%;
  --bonus-amount-font-weight: 400;
  --wheel-type-text-font-family: "Bebas Neue", sans-serif;
  --wheel-amount-text-font-family: "Bebas Neue", sans-serif;
}

html[theme="pink"] {
  --wheel-deg: 18deg;
  --wheel-radius: 100%;
  --wheel-size: 576px;
  --wheel-body-size: 99%;
  --bonus-type-font-size: 28px;
  --bonus-amount-font-size: 28px;
  --reg-bonus-type-font-size: 11px;
  --reg-bonus-amount-font-size: 15px;
  --bonus-type-font-weight: 400;
  --bonus-type-line-height: normal;
  --bonus-type-top: 15px;
  --bonus-amount-bottom: 39%;
  --bonus-amount-font-weight: 400;
  --wheel-type-text-font-family: "Bebas Neue", sans-serif;
  --wheel-amount-text-font-family: "Bebas Neue", sans-serif;
}

html[theme="orange"] {
  --wheel-deg: 0;
  --wheel-radius: 0;
  --wheel-size: 620px;
  --wheel-body-size: 92%;
  --bonus-type-font-size: 28px;
  --bonus-amount-font-size: 28px;
  --reg-bonus-type-font-size: 11px;
  --reg-bonus-amount-font-size: 15px;
  --bonus-type-font-weight: 400;
  --bonus-type-line-height: normal;
  --bonus-type-top: 15px;
  --bonus-amount-bottom: 39%;
  --bonus-amount-font-weight: 400;
  --wheel-type-text-font-family: "Bebas Neue", sans-serif;
  --wheel-amount-text-font-family: "Bebas Neue", sans-serif;
}

html[theme="lightPurple"] {
  --wheel-deg: 18deg;
  --wheel-radius: 0;
  --wheel-size: 660px;
  --wheel-body-size: 100%;
  --bonus-type-font-size: 38px;
  --bonus-amount-font-size: 45px;
  --reg-bonus-type-font-size: 14px;
  --reg-bonus-amount-font-size: 20px;
  --bonus-type-font-weight: 400;
  --bonus-type-line-height: normal;
  --bonus-type-top: 15px;
  --bonus-amount-bottom: 42%;
  --bonus-amount-font-weight: 400;
  --wheel-type-text-font-family: "Bebas Neue", sans-serif;
  --wheel-amount-text-font-family: "Bebas Neue", sans-serif;
}

@media screen and (max-height: 760px) {
  html[theme="green"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 16px;
    --bonus-amount-bottom: 45%;
    --bonus-type-top: 2px;
    --bonus-type-line-height: 18px;
  }

  html[theme="pink"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }

  html[theme="orange"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 10px;
  }

  html[theme="lightPurple"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }
}

@media screen and (max-width: 1400px) {
  html[theme="green"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 20px;
    --bonus-amount-font-size: 22px;
    --bonus-type-line-height: 25px;
    --bonus-amount-bottom: 47%;
    --bonus-type-top: 0;
  }

  html[theme="purple"] {
    --wheel-size: 500px;
    --bonus-type-font-size: 18px;
    --bonus-amount-font-size: 22px;
    --bonus-type-top: 10px;
    --bonus-amount-bottom: 40%;
  }

  html[theme="pink"] {
    --wheel-size: 380px;
    --bonus-type-font-size: 20px;
    --bonus-amount-font-size: 22px;
    --bonus-type-top: 12px;
    --bonus-amount-bottom: 39%;
  }

  html[theme="orange"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 20px;
    --bonus-amount-font-size: 20px;
    --bonus-type-top: 15px;
    --bonus-amount-bottom: 39%;
  }

  html[theme="lightPurple"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 20px;
    --bonus-amount-font-size: 24px;
    --bonus-type-top: 12px;
    --bonus-amount-bottom: 42%;
  }
}

@media screen and (max-width: 1400px) and (max-height: 760px) {
  html[theme="green"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 16px;
    --bonus-amount-bottom: 45%;
    --bonus-type-top: 2px;
    --bonus-type-line-height: 18px;
  }

  html[theme="pink"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }

  html[theme="orange"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 10px;
  }

  html[theme="lightPurple"] {
    --wheel-size: 330px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }
}

@media screen and (max-width: 990px) {
  html[theme="purple"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 18px;
    --bonus-type-top: 12px;
    --bonus-amount-bottom: 40%;
  }
}

@media screen and (max-height: 860px) {
  html[theme="purple"] {
    --wheel-size: 500px;
    --bonus-type-font-size: 18px;
    --bonus-amount-font-size: 20px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 15px;
  }
}

@media screen and (max-width: 990px) and (max-height: 860px) {
  html[theme="purple"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 14px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }
}

@media screen and (max-height: 720px) {
  html[theme="purple"] {
    --wheel-size: 400px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 14px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }
}

@media screen and (max-height: 580px) {
  html[theme="purple"] {
    --wheel-size: 300px;
    --bonus-type-font-size: 12px;
    --bonus-amount-font-size: 14px;
    --bonus-amount-bottom: 41%;
    --bonus-type-top: 8px;
  }
}

@media screen and (max-width: 520px) {
  html[theme="green"] {
    --wheel-size: 324px;
    --bonus-type-font-size: 15px;
    --bonus-amount-font-size: 18px;
    --bonus-type-line-height: normal;
    --bonus-type-top: 0px;
    --bonus-amount-bottom: 47%;
    --bonus-type-font-weight: 400;
  }

  html[theme="purple"] {
    --wheel-size: 300px;
    --wheel-body-size: 82%;
    --bonus-type-font-size: 12px;
    --bonus-amount-font-size: 14px;
    --bonus-type-line-height: 20px;
    --bonus-type-top: 5px;
    --bonus-amount-bottom: 40%;
    --bonus-type-font-weight: 400;
    --bonus-amount-font-weight: 400;
  }

  html[theme="pink"] {
    --wheel-size: 295px;
    --bonus-type-font-size: 14px;
    --bonus-amount-font-size: 16px;
    --bonus-amount-bottom: 40%;
    --bonus-type-top: 12px;
  }

  html[theme="orange"] {
    --wheel-size: 324px;
    --bonus-type-font-size: 18px;
    --bonus-amount-font-size: 20px;
    --bonus-type-top: 10px;
    --bonus-amount-bottom: 38%;
  }

  html[theme="lightPurple"] {
    --wheel-size: 340px;
    --bonus-type-font-size: 18px;
    --bonus-amount-font-size: 20px;
    --bonus-type-top: 10px;
  }
}

