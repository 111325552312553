html, body, #root, .main {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background: var(--background-gradient);
}

.main {
  display: flex;
  align-items: center;

  .background-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--main-background), var(--main-background-secondary) no-repeat left bottom / cover;
  }
}

html[theme="green"] {
  .wheel__dot {
    display: block;
  }

  .left-text {
    width: 390px;

    p {
      color: var(--main-text-color);
      text-align: center;
      text-shadow: 0 2px 23.1px var(--main-text-shadow);
      text-transform: uppercase;
      font-family: "Noto Sans Armenian", sans-serif;
      font-size: 56px;
      font-weight: 400;
    }
  }

  .right-text {
    width: 336px;

    p {
      color: var(--main-text-color);
      text-align: center;
      text-shadow: 0 2px 23.1px var(--main-text-shadow);
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 56px;
      font-weight: 400;
    }
  }
}

html[theme="pink"] {
  .main {
    align-items: flex-end;
  }

  .wheel-wrap {
    justify-content: flex-start;
  }

  .right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 532px;
    margin-left: 70px;

    p {
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-family: "Bebas Neue", sans-serif;
      font-size: 41px;
      font-weight: 400;
      line-height: 61px;
    }
  }
}

html[theme="orange"] {
  .wheel-wrap {
    justify-content: flex-start;
  }

  .right-text {
    display: flex;
    width: 583px;
    padding: 32px 16px;
    margin-left: 48px;
    background: #F3853B;
    border-radius: 18px;

    p {
      color: white;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
    }
  }
}

html[theme="lightPurple"] {
  .wheel-wrap {
    justify-content: flex-end;
  }

  .left-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 609px;
    margin-right: 38px;

    p {
      color: white;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 62px;
      font-weight: 700;
      line-height: 75px;
      text-align: center;
    }
  }
}

html[theme="purple"] {
  .main {
    align-items: flex-end;
  }

  .left-text {
    display: none;
  }
}

html[lang="am"] {
  .wheel__spin, .left-text, .right-text {
    font-family: "Noto Sans Armenian", sans-serif !important;
  }

  .won-content, .amount-wrap {
    * {
      font-family: "Noto Sans Armenian", sans-serif !important;
    }
  }
}
