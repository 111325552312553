html[theme="green"] {
  --background-gradient: linear-gradient(173.57deg, #18382D 3.97%, #1D7155 47.36%, #18382D 94.93%);
  --main-background: url('../../images/green/background.svg') no-repeat center / auto 100%;
  --main-background-secondary: none;
  --wheel-arrow: url('../../images/green/wheel-arrow.svg') no-repeat left top / 100%;
  --wheel-border: url('../../images/green/wheel-border.svg');
  --wheel-body: url('../../images/green/wheel-body.svg');
  --win-modal-background: url('../../images/green/win-modal-background.svg');
  --reg-modal-background: url('../../images/green/reg-modal-background.svg');
  --wheel-body-dot: linear-gradient(180deg, #FED85E 25.83%, #DFAF36 98.19%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  --wheel-text-color: white;
  --wheel-text-stroke: 1px 1px 0 #C4A340, -1px 1px 0 #C4A340, -1px -1px 0 #C4A340, 1px -1px 0 #C4A340;
  --wheel-text-shadow: #00000040;
  --main-text-color: #EAC95B;
  --main-text-shadow: #59C8A4;
  --reg-modal-form-background: #13141F;
  --spin-button-box-shadow: 0 10.1px 21.9px 0 #725305CC;
  --spin-button-background: linear-gradient(355.92deg, #16A778 43.26%, #63CDAB 113.87%);
  --spin-button-text-stroke: 1px 1px 0 #C4A340, -1px 1px 0 #C4A340, -1px -1px 0 #C4A340, 1px -1px 0 #C4A340;
  --spin-button-box-shadow1: 0px -1.1px 4.6px 0px #67CEAD inset, 0px 4.6px 4.6px 0px #00000040;
  --spin-button-background1: linear-gradient(330.9deg, #2BAE83 24.2%, #63D7B2 91.01%);
}

html[theme="purple"] {
  --background-gradient: radial-gradient(41.66% 60.07% at 28.54% 53.03%, #9057D2 0%, #020069 100%);
  --main-background: url('../../images/purple/background.svg') no-repeat center / auto 100%;
  --main-background-secondary: url('../../images/purple/background-secondary.svg');
  --wheel-arrow: url('../../images/purple/wheel-arrow.svg') no-repeat center 30% / 16% auto;
  --wheel-border: url('../../images/purple/wheel-border.svg');
  --wheel-body: url('../../images/purple/wheel-body.svg');
  --win-modal-background: url('../../images/purple/win-modal-background.svg');
  --reg-modal-background: url('../../images/purple/reg-modal-background.svg');
  --wheel-body-dot: linear-gradient(180deg, #FED85E 25.83%, #DFAF36 98.19%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  --wheel-text-color: white;
  --wheel-text-stroke: 1px 1px 0 #661CB5, -1px 1px 0 #661CB5, -1px -1px 0 #661CB5, 1px -1px 0 #661CB5;
  --wheel-text-shadow: #00000040;
  --main-text-color: #EAC95B;
  --main-text-shadow: #59C8A4;
  --reg-modal-form-background: #2F1270;
  --spin-button-box-shadow: none;
  --spin-button-background: none;
  --spin-button-text-stroke: none;
  --spin-button-box-shadow1: none;
  --spin-button-background1: none;
}

html[theme="pink"] {
  --background-gradient: #F8B9CE;
  --main-background: url('../../images/pink/background.svg') no-repeat left bottom / auto;
  --main-background-secondary: none;
  --wheel-arrow: url('../../images/pink/wheel-arrow.svg') no-repeat top center / 31% auto;
  --wheel-body: url('../../images/pink/wheel-body.svg');
  --win-modal-background: url('../../images/pink/win-modal-background.svg');
  --reg-modal-background: url('../../images/pink/reg-modal-background.svg');
  --wheel-border: #FDF397;
  --wheel-body-dot: none;
  --wheel-text-color: white;
  --wheel-text-stroke: none;
  --wheel-text-shadow: none;
  --reg-modal-form-background: #D196AC;
  --spin-button-box-shadow: 2px -4px 7px 0px #FFFFFF99 inset, 0 4px 4px 0 #00000040;
  --spin-button-background: linear-gradient(96.39deg, #B681F1 0%, #C69BF4 100%);
  --spin-button-text-stroke: none;
  --spin-button-box-shadow1: none;
  --spin-button-background1: none;
}

html[theme="orange"] {
  --background-gradient: none;
  --main-background: url('../../images/orange/background.svg') no-repeat center / cover;
  --main-background-secondary: none;
  --wheel-arrow: url('../../images/orange/wheel-arrow.svg') no-repeat center 9% / 29% auto;
  --wheel-body: url('../../images/orange/wheel-body.svg');
  --win-modal-background: url('../../images/orange/win-modal-background.svg');
  --reg-modal-background: url('../../images/orange/reg-modal-background.svg');
  --wheel-border: url('../../images/orange/wheel-border.svg');
  --wheel-body-dot: none;
  --wheel-text-color: white;
  --wheel-text-stroke: none;
  --wheel-text-shadow: none;
  --reg-modal-form-background: #FFFFFF;
  --spin-button-box-shadow: none;
  --spin-button-background: none;
  --spin-button-text-stroke: none;
  --spin-button-box-shadow1: none;
  --spin-button-background1: none;
}

html[theme="lightPurple"] {
  --background-gradient: linear-gradient(337.18deg, #160044 3.17%, rgba(0, 0, 0, 0) 51.18%), linear-gradient(170.22deg, #3C208B 7.35%, #9834D6 43.17%, #8F36C6 53.28%, #561EA7 62.71%, #5F3BC7 93.93%);
  --main-background: url('../../images/lightPurple/background.svg') no-repeat center / cover;
  --main-background-secondary: none;
  --wheel-arrow: url('../../images/lightPurple/wheel-arrow.svg') no-repeat center top / 32% auto;
  --wheel-body: url('../../images/lightPurple/wheel-body.svg');
  --win-modal-background: url('../../images/lightPurple/win-modal-background.svg');
  --reg-modal-background: url('../../images/lightPurple/reg-modal-background.svg');
  --wheel-border: none;
  --wheel-body-dot: none;
  --wheel-text-color: white;
  --wheel-text-stroke: none;
  --wheel-text-shadow: none;
  --reg-modal-form-background: #13141F;
  --spin-button-box-shadow: none;
  --spin-button-background: none;
  --spin-button-text-stroke: none;
  --spin-button-box-shadow1: none;
  --spin-button-background1: none;
}

@media screen and (max-height: 760px) {
  html[theme="pink"] {
    --main-background: url('../../images/pink/background.svg') no-repeat left bottom / auto 500px;
  }
}

@media screen and (max-width: 1400px) {
  html[theme="pink"] {
    --main-background: url('../../images/pink/background.svg') no-repeat left bottom / auto 630px;
  }

  html[theme="purple"] {
    --main-background: url('../../images/purple/background.svg') no-repeat bottom center / auto 90%;
  }
}

@media screen and (max-width: 1400px) and (max-height: 760px) {
  html[theme="purple"] {
    --main-background: url('../../images/purple/background.svg') no-repeat bottom center / auto 100%;
  }

  html[theme="pink"] {
    --main-background: url('../../images/pink/background.svg') no-repeat left bottom / auto 500px;
  }
}

@media screen and (max-width: 990px) {
  html[theme="purple"] {
    --main-background: url('../../images/purple/background.svg') no-repeat bottom center / auto 75%;
  }
}

@media screen and (max-width: 990px) and (max-height: 760px) {
  html[theme="purple"] {
    --main-background: url('../../images/purple/background.svg') no-repeat bottom center / auto 100%;
  }
}

@media screen and (max-width: 720px) {
  html[theme="pink"] {
    --main-background: url('../../images/pink/background-mobile.svg') no-repeat left top/ cover;
  }
}

@media screen and (max-width: 520px) {
  html[theme="green"] {
    --main-background: url('../../images/green/background-mobile.svg') no-repeat center / cover;
  }

  html[theme="purple"] {
    --main-background: url('../../images/purple/background-mobile.svg') no-repeat center / cover;
    --main-background-secondary: url('../../images/purple/background-secondary.svg');
  }

  html[theme="orange"] {
    --main-background: url('../../images/orange/background-mobile.svg') no-repeat center / cover;
  }

  html[theme="lightPurple"] {
    --main-background: url('../../images/lightPurple/background-mobile.svg') no-repeat center / cover;
  }
}
