@import "./components/wheel-responsive";

@media screen and (max-height: 760px) {
  .modal {
    scale: 0.8;
    max-width: 100%;
    max-height: 100%;
  }

  html[theme="green"] {
    .left-text {
      width: 195px;

      p {
        font-size: 28px;
      }
    }

    .right-text {
      width: 168px;

      p {
        font-size: 28px;
      }
    }
  }

  html[theme="lightPurple"] {
    .left-text {
      width: 300px;

      p {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }

  html[theme="pink"] {
    .right-text {
      width: 265px;
      margin-left: 70px;

      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  html[theme="orange"] {
    .right-text {
      width: 290px;
      padding: 16px 8px;
      margin-left: 48px;
      border-radius: 9px;

      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  html[theme="green"] {
    .right-text {
      width: 230px;

      p {
        font-size: 36px;
      }
    }

    .left-text {
      width: 250px;

      p {
        font-size: 36px;
      }
    }
  }

  html[theme="pink"] {
    .right-text {
      width: 360px;

      p {
        font-size: 26px;
        line-height: 36px;
      }
    }
  }

  html[theme="orange"] {
    .right-text {
      width: 300px;
      padding: 20px 16px;

      p {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  html[theme="lightPurple"] {
    .left-text {
      width: 300px;

      p {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }
}

@media screen and (max-width: 1400px) and (max-height: 760px) {
  html[theme="green"] {
    .left-text {
      width: 195px;

      p {
        font-size: 28px;
      }
    }

    .right-text {
      width: 168px;

      p {
        font-size: 28px;
      }
    }
  }

  html[theme="lightPurple"] {
    .left-text {
      width: 300px;

      p {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }

  html[theme="pink"] {
    .right-text {
      width: 265px;
      margin-left: 70px;

      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  html[theme="orange"] {
    .right-text {
      width: 290px;
      padding: 16px 8px;
      margin-left: 48px;
      border-radius: 9px;

      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  html[theme="green"] {
    .right-text {
      display: none;
    }

    .left-text {
      width: 500px;
      margin-bottom: 80px;

      p {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }

  html[theme="pink"] {
    .right-text {
      margin: 0 0 40px 30px;
    }
  }

  html[theme="orange"] {
    .right-text {
      margin: 0 0 40px 0;
    }
  }

  html[theme="lightPurple"] {
    .left-text {
      width: 500px;
      margin: 0 0 40px 0;

      p {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }
}

@media screen and (max-height: 580px) and (max-width: 990px) {
  html[theme="green"] {
    .right-text, .left-text {
      display: none;
    }
  }

  html[theme="orange"] {
    .right-text, .left-text {
      display: none;
    }
  }

  html[theme="purple"] {
    .left-text {
      display: none !important;
    }
  }
}

@media screen and (max-width: 720px) {
  html[theme="pink"] {
    .main {
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 520px) {
  html[theme="green"] {
    .left-text {
      width: 80%;
      margin-bottom: 34px;

      p {
        color: white;
        text-shadow: none;
        font-size: 32px;
        line-height: 39px;
      }
    }
  }

  html[theme="pink"] {
    .right-text {
      width: 80%;
      padding: 16px;
      margin-bottom: 40px;
      background: #C599F4;
      border-radius: 16px;

      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }

  html[theme="orange"] {
    .right-text {
      width: 80%;
      padding: 16px;
      margin-bottom: 50px;
      border-radius: 16px;
      text-align: center;

      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }

  html[theme="lightPurple"] {
    .right-text {
      width: 80%;
      padding: 16px;
      margin-bottom: 40px;
      border-radius: 16px;
      text-align: center;

      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }

  html[theme="purple"] {
    .main {
      align-items: center;
    }

    .left-text {
      display: block;
      width: 90%;
      margin-bottom: 40px;
      text-align: center;

      p {
        color: white;
        font-family: "Montserrat", sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
      }
    }
  }
}

@media screen and (max-height: 600px) and (max-width: 990px) {
  html[theme="pink"] {
    .wheel-wrap {
      position: relative;
      width: auto;
    }

    .right-text {
      padding: 0;
      margin: 0;
      background: none;

      p {
        display: none;
      }
    }

    .wheel__spin {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      bottom: auto;
      padding: 9px 16px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  html[theme="lightPurple"] {
    .left-text {
      padding: 0;
      margin: 0;
      background: none;

      p {
        display: none;
      }
    }

    .wheel__spin {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      bottom: auto;
      padding: 9px 16px;

      span {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

@media screen and (max-device-width: 520px) and (orientation: portrait) {
  .main {
    align-items: flex-start;
  }

  .wheel-wrap {
    margin-top: 10%;
  }
}

@media screen and (max-device-width: 720px) and (orientation: portrait) {
  html[theme="pink"] {
    .main {
      align-items: flex-start;
    }

    .wheel-wrap {
      margin-top: 10%;
    }

  }
}
