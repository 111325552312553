@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

html[theme="green"] {
  @import url("https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap");
}

html[theme="purple"] {
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
}

html[theme="pink"] {
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
}
