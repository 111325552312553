.wheel-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wheel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--wheel-size);
  height: var(--wheel-size);
  background: var(--wheel-border) no-repeat center center / contain;
  border-radius: var(--wheel-radius);
  rotate: var(--wheel-deg);
  user-select: none;

  &__dot {
    position: absolute;
    display: none;
    z-index: 2;
    width: 19.8%;
    height: 19.8%;
    background: var(--wheel-body-dot);
    border-radius: 138px;

    &.animate {
      transition: rotate cubic-bezier(0.20, 1, 0.20, 1) 6s;
    }
  }

  &__chip {
    position: absolute;
    bottom: -3%;
    left: 10%;
    z-index: 5;
  }

  &__crown {
    position: absolute;
    z-index: 5;
    top: -20%;
  }

  &__arrow {
    position: absolute;
    top: -7%;
    left: 50%;
    z-index: 1;
    width: 42.7%;
    height: 56%;
    background: var(--wheel-arrow);
    translate: -50%;
  }

  &__body {
    position: relative;
    width: var(--wheel-body-size);
    height: var(--wheel-body-size);
    background: var(--wheel-body) no-repeat center / 100%;
    overflow: hidden;

    &.animate {
      transition: rotate cubic-bezier(0.20, 1, 0.20, 1) 5s;
    }

    &__bonus-item {
      position: absolute;
      top: 2%;
      left: 35.5%;
      width: 29.4%;
      height: 47.6%;
      transform-origin: center bottom;

      @for $i from 1 through 10 {
        $value: calc(($i - 1) * 36);

        &:nth-of-type(#{$i}) {
          rotate: #{$value}deg;
        }
      }
    }

    &__bonus-type {
      margin-top: var(--bonus-type-top);
      color: var(--wheel-text-color);
      text-align: center;
      text-shadow: var(--wheel-text-stroke);
      text-transform: uppercase;
      font-family: var(--wheel-type-text-font-family);
      font-size: var(--bonus-type-font-size);
      font-weight: var(--bonus-type-font-weight);
      line-height: var(--bonus-type-line-height);
      filter: drop-shadow(0 3.8px 3.8px var(--wheel-text-shadow));
    }

    &__bonus-amount {
      position: absolute;
      bottom: var(--bonus-amount-bottom);
      width: 100%;
      padding: 0 0 2px 0;
      color: var(--wheel-text-color);
      text-align: right;
      text-shadow: var(--wheel-text-stroke);
      text-transform: uppercase;
      font-family: var(--wheel-amount-text-font-family);
      font-size: var(--bonus-amount-font-size);
      font-style: italic;
      font-weight: var(--bonus-amount-font-weight);
      filter: drop-shadow(0 3.8px 3.8px var(--wheel-text-shadow));
      rotate: -89deg;
    }
  }

  &__spin {
    position: absolute;
    z-index: 3;
    padding: 22px;
    box-shadow: var(--spin-button-box-shadow);
    background: var(--spin-button-background);
    border: none;
    border-radius: 45px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      box-shadow: var(--spin-button-box-shadow1);
      background: var(--spin-button-background1);
      border-radius: 45px;
      content: " ";
      translate: -50% -50%;
    }

    span {
      color: var(--wheel-text-color);
      text-shadow: var(--spin-button-text-stroke);
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 38px;
      font-weight: 800;
      line-height: 46px;
      filter: drop-shadow(0 3.8px 3.8px var(--wheel-text-shadow));
    }
  }
}

html[theme="pink"] {
  .wheel-wrap {
    margin-bottom: 170px;
  }

  .wheel {
    margin-left: 200px;

    &__arrow {
      top: -7%;
      left: 36%;
      translate: 0;
      rotate: -18deg;
    }

    &__spin {
      position: relative;
      margin-top: 40px;

      span {
        color: white;
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
      }
    }

    $textColors: #F9C130, #B703C7, #0093A2, #661CB5, #9E9E00, #96BF33, #EC1E63, #0093A2, #661CB5, #FE8A21;

    &__body {
      @for $i from 1 through 10 {
        &__bonus-item:nth-of-type(#{$i}) {
          $color: nth($textColors, $i);

          .wheel__body__bonus-amount, .wheel__body__bonus-type {
            text-shadow: 1px 1px 0 $color, -1px 1px 0 $color, -1px -1px 0 $color, 1px -1px 0 $color;
          }
        }
      }
    }
  }
}

html[theme="orange"] {
  .wheel {
    margin-left: 5%;

    &__spin {
      span {
        color: white;
        font-family: "Bebas Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__body {
      &__bonus-item {
        &:nth-child(odd) {

          .wheel__body__bonus-amount, .wheel__body__bonus-type {
            color: #563025;
            text-shadow: none;
          }
        }

        &:nth-child(even) {
          .wheel__body__bonus-amount, .wheel__body__bonus-type {
            color: white;
            text-shadow: none;
          }
        }
      }
    }
  }
}

html[theme="lightPurple"] {
  .wheel {
    margin-right: 5%;

    &__arrow {
      top: -8%;
      left: 36%;
      translate: 0;
      rotate: -18deg;
    }

    &__spin {
      position: relative;
      margin-top: 40px;
      background: linear-gradient(96.39deg, #FFD800 0%, #FFB800 100%);

      span {
        color: #8F27FF;
        text-shadow: 2px 2px 0 #FFBB01, -2px 2px 0 #FFBB01, -2px -2px 0 #FFBB01, 2px -2px 0 #FFBB01;
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
      }
    }

    $textColors: #F9C130, #B703C7, #0093A2, #661CB5, #9E9E00, #96BF33, #EC1E63, #0093A2, #661CB5, #FE8A21;

    &__body {
      @for $i from 1 through 10 {
        &__bonus-item:nth-of-type(#{$i}) {
          $color: nth($textColors, $i);

          .wheel__body__bonus-type {
            color: #40408D;
          }

          .wheel__body__bonus-amount, .wheel__body__bonus-type {
            text-shadow: 1px 1px 0 $color, -1px 1px 0 $color, -1px -1px 0 $color, 1px -1px 0 $color;
          }
        }
      }
    }
  }
}

html[theme="purple"] {
  .wheel {
    position: absolute;
    bottom: 16%;
  }
}
