.reg-content {
  display: flex;
  width: 776px;
  min-height: 520px;
  overflow: hidden;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 50%;
    height: 100%;
    background: var(--reg-modal-background) no-repeat center/cover;
  }

  .win-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    overflow: hidden;

    .wheel-wrap {
      justify-content: center;
    }

    .wheel {
      width: 305px;
      height: 305px;
      margin-bottom: 10px;

      &__spin {
        scale: 0.4;
      }

      &__chip, &__crown {
        display: none;
      }

      &__body {
        &__bonus-type {
          margin-top: 7px;
          font-size: var(--reg-bonus-type-font-size);
          line-height: normal;
        }

        &__bonus-amount {
          bottom: 42%;
          font-size: var(--reg-bonus-amount-font-size);
        }
      }
    }

    .amount-wrap {
      max-width: 80%;
      margin-top: 20px;

      h3 {
        color: white;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        color: #E8C657;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
      }
    }
  }

  .form-side {
    width: 50%;
    background: var(--reg-modal-form-background);
  }
}

html[theme="pink"] {
  .reg-content {
    min-height: 560px;

    .wheel-wrap {
      align-items: center;
      margin: 0 0 10px 0;
    }

    .wheel {
      margin: 0;
    }

    .amount-wrap {
      width: 100%;
      max-width: 90%;
      padding: 16px 0;
      background: #C599F4;
      border-radius: 16px;

      h3 {
        color: white;
      }

      p {
        color: white;

        span {
          order: 1;
          margin-left: 10px;
        }
      }
    }
  }
}

html[theme="orange"] {
  .reg-content {
    .amount-wrap {
      width: 100%;
      padding: 16px 0;
      background: #F3853B;
      border-radius: 16px;

      h3 {
        color: white;
      }

      p {
        color: white;

        span {
          margin-right: 10px;
          font-size: 60px;
          line-height: 73px;
        }
      }
    }
  }
}

html[theme="purple"] {
  .reg-content {
    .wheel {
      position: relative;
      bottom: 0;
    }
  }
}

html[theme="lightPurple"] {
  .reg-content {
    .wheel {
      margin-right: 0;
    }

    .amount-wrap {
      p {
        span {
          margin-right: 10px;
          font-size: 60px;
          line-height: 73px;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .modal.reg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    scale: 1;
  }

  .reg-content {
    flex-direction: column;
    width: 100%;
    min-height: 100% !important;
    padding: 40px 16px;

    &__background {
      width: 100%;
    }

    .win-side {
      width: 100%;

      .amount-wrap {
        max-width: 100% !important;
        margin: 0;
      }

      .wheel-wrap {
        position: relative;
        display: none
      }
    }

    .form-side {
      width: 100%;
      min-height: 100%;
      margin-top: 32px;
    }
  }

  html[theme="pink"] {
    .reg-content {
      .amount-wrap {
        padding: 16px;
        border-radius: 16px;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 32px;
          line-height: 39px;
        }
      }
    }
  }

  html[theme="orange"] {
    .reg-content {
      .amount-wrap {
        padding: 16px;
        border-radius: 16px;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 24px;
          line-height: 29px;

          span {
            width: 100%;
            font-size: 40px;
            line-height: 48px;
          }
        }
      }
    }
  }

  html[theme="lightPurple"] {
    .reg-content {
      .amount-wrap {
        border-radius: 16px;
        padding: 0;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        p {
          font-size: 32px;
          line-height: 39px;

          span {
            width: 100%;
            font-size: 48px;
            line-height: 58px;
          }
        }
      }
    }
  }
}


