.modal {
  padding: 0;
  border: none;
  border-radius: 16px;
  background: none;
  background: var(--background-gradient);

  &::backdrop {
    background: rgba(0, 0, 0, 0.7);
  }

  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 12px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
    }
  }
}

html[theme="orange"] {
  .modal.reg {
    .close-icon {
      img {
        filter: invert(30%);
      }
    }
  }
}
